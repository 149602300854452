body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* local page styles */
html, html body {
  /* background-color: #26619c; */
  font-family: 'Roboto', sans-serif;
}

html h1 {
  font-size: 26px;
  margin-left: 10px;
}

html h2 {
  font-size: 22px;
  margin-left: 10px;
}

html h3 {
  font-size: 14px;
  margin-left: 10px;
}

html h4 {
  font-size: 16px;
}

.example {
  margin-top: 40px;
}



#videoBG {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: -1;
}

@media (min-aspect-ratio: 1790/877) {
  #videoBG {
      width:100%;
      height: auto;
  }
}

@media (max-aspect-ratio: 1790/877) {
  #videoBG { 
      width:auto;
      height: 100%;
  }
}

.progtrckr {
  text-align: center;
  padding-bottom: 16px;
}

.progtrckr li {
  margin-bottom: 10px;
}

.progtrckr em {
  pointer-events: none;
}

.progtrckr span {
  pointer-events: none;
}


.val-err-tooltip {
  background-color: red;
  padding: 3px 5px 3px 10px;
  font-size: 14px;
  color: #fff;
}

.step {
  background-color:#f8f8ff;
  min-height: 450px;
  padding: 10px;
  text-align: center;
}

/* .step-progress {
  background-color: #f8f8ff;
} */

html .row, html .form-horizontal .form-group {
  margin: 0;
}

.footer-buttons {
  margin-top: 10px;
  text-align: center;
}

.btn-next {
  border-color: lightgrey !important;
}

.btn-prev {
  border-color: lightgrey !important;
}


/* html .step3 label, html .step4 label {
  font-size: 20px;
  text-align: left;
} */

html .form-horizontal .control-label {
  text-align: left;
}

.review .txt {
  font-size: 20px;
  text-align: left;
  margin: 0;
  padding: 0;
}

html body .saving {
  background-color: #5cb85c;
  width: 90%;
  padding: 5px;
  font-size: 16px;
}

code {
  position: relative;
  left: 12px;
  line-height: 25px;
}

.eg-jump-lnk {
  margin-top: 50px;
  font-style: italic;
}

.lib-version {
  font-size: 12px;
  background-color: rgba(255, 255, 0, 1);
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px;
}

html .content {
  margin-left: 10px;
}

span.red {
  color: #d9534f;
}

span.green {
  color: #3c763d;
}

span.bold {
  font-weight: bold;
}

html .hoc-alert {
  margin-top: 20px;
}

html .form-block-holder {
  margin-top: 20px !important;
}

/* overwrite the stepzilla styles */
html ol.progtrckr li.progtrckr-todo:before {
  content: "\2022";
  background-color: white;
  border-radius: 1.2em;
  line-height: 1.2em;
}

html ol.progtrckr li.progtrckr-todo:hover:before {
  color: blue;
}

html ol.progtrckr li {
  color: white !important;
  -webkit-text-stroke: 0.5px black;
  font-weight: 900;
  font-size: 18px;
}

html ol.progtrckr li.progtrckr-todo {
  border-bottom: 4px solid white;
}

html ol.progtrckr li.progtrckr-doing {
  border-bottom: 4px solid blue;
}

html ol.progtrckr li.progtrckr-doing:before {
  background-color: blue;
}

html ol.progtrckr li.progtrckr-done {
  border-bottom: 4px solid #5cb85c;
}


#exList {
  background-color: #333;
  padding: 8px;
  text-align: center;
  color: #fff;
}

#exList .title {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 5px;
}

#exList .eg {
  display: inline-block;
  padding: 5px;
  border: solid 1px;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
}

#exList .eg.sel {
  background-color: #000;
}

.container {
  display: none;
} 

table.MuiTable-root {
  white-space: pre;
}
